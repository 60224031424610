import React, { useEffect, useState } from "react";
import logo from "../static/images/logo.svg";
import person from "../static/images/person.png";
import pdtr1 from "../static/images/pdtr1.jpg";
import pdtr2 from "../static/images/pdtr2.jpg";
import pdtr3 from "../static/images/pdtr3.jpg";
import pdtr4 from "../static/images/pdtr4.jpg";
import method1 from "../static/images/method1.jpg";
import method2 from "../static/images/method2.jpg";
import method3 from "../static/images/method3.jpg";
import method4 from "../static/images/method4.jpg";
import method5 from "../static/images/method5.jpg";
import contact from "../static/images/contact.jpg";
import { FaCheck } from "react-icons/fa";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import { FaInstagram } from "react-icons/fa";
import { AiOutlineFacebook } from "react-icons/ai";
import { Typewriter } from "react-simple-typewriter";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoMdClose } from "react-icons/io";
import axios from "axios";
import { api_url } from "../constants";
import { FaRegCircleCheck } from "react-icons/fa6";

const Home = () => {
  const pdtrs = [
    {
      image: pdtr4,
      description:
        "Sechele si urmari ale traumelor, facturilor, dislocarilor, sindromul durerii posttraumatice, afectiuni ale ligamentelor si tendoanelor",
    },
    {
      image: pdtr2,
      description:
        "Afectiuni ale coloanei vertebrale si articulatii: ostehondroza, osteoartroza, scolioza, cifoza, spondiloza,  epicondelita, picior plat",
    },
    {
      image: pdtr3,
      description:
        "Afectiuni neurologice: migrene, dureri de cap, nevralgii intercostale, distanta neuro-vegetativa, epilepsie",
    },
    {
      image: pdtr1,
      description:
        "Afectiuni ale organelor interne: colecistita, gastrita, ulcer stomacal si duodenal, pancreatita, constipatie",
    },
  ];

  const questions = [
    {
      question: "Cat dureaza si cum se desfasoara o sedinta?",
      answer:
        "Durata unei sedinte de terapie este variabila, in functie de procedura executata de terapeut - intre 30 de minute si o ora. Fiecare sedinta de tratament este personalizata, in functie de afectiune si reactia pacientului.",
    },
    {
      question: "Ce trebuie sa faca pacientul in timpul sedintei sau dupa?",
      answer:
        "in primul rind sa dezactivati soneria telefonului si sa va ginditi numai la sanatatea dumneavoastra! Toate sedintele se vor desfasura in liniste, iar intre seturile de miscari pe care terapeutul le va aplica veti ramine sa va odihniti, singur in camera.",
    },
    {
      question:
        "Dupa ce mi-am rezolvat problemele, cind trebuie sa mai revin la terapie?",
      answer:
        "sedintele de terapie au o buna remanenta a efectelor in timp, deci nu trebuie sa traiti cu teama ca daca nu mai mergi la terapie, o sa va revina simptomele de care tocmai ati scapat. Ceea ce nu inseamna, ca puteti reveni la greselile anterioare, iar corpul dumneavoastra o sa ramana sanatos.",
    },
    {
      question:
        "Cum si unde va pot contacta cei care vor sa beneficieze de terapie?",
      answer:
        "Puteti face o programare la numarul 068504628, sau sa scrieti un mesaj la adresa electronica stopdureremd@gmail.com",
    },
  ];

  const [selectedQuestion, setSelectedQuestion] = useState(questions[0]);
  const [isAnswerChanging, setIsAnswerChanging] = useState(false);

  useEffect(() => {
    if (isAnswerChanging) {
      setTimeout(() => {
        setIsAnswerChanging(false);
      }, 200);
    }
  }, [isAnswerChanging]);

  const methods = [
    {
      title: "Kinesiologia aplicata",
      image: method1,
      description: [
        "Kinesiologia aplicata, terapia punctelor trigger si FDM sunt terapii manuale de tratare ale afectiunilor scheleto-musculare,  a coloanei vertebrale si ale articulatiilor. ",
        "Totodata kinesiologia aplicata e foarte eficienta in inlaturarea durerilor de cap, migrenelor, problemelor organelor interne,  stresului indelungat si starii de incordare permanenta. Se poate aplica  tuturor.",
      ],
    },
    {
      title: "Terapia Bowen",
      image: method2,
      description: [
        "Terapia Bowen este o alta terapie holistica, non-invaziva,  nedureroasa , ce se aplica manual pe anumite puncte la nivelul diverselor zone ale corpului.Terapia este utilizata pentru ameliorarea durerilor si se adreseaza tuturor categoriilor de varsta.",
        "Este eficienta in echilibrarea starilor psiho-emotionale, normalizeaza circuitele energiei, inlatura tensiunile musculare. Este recunoscuta pentru eficienta sa in tratarea afectiunilor interne si reducerea duratei convalescentei.",
      ],
    },
    {
      title: "Consultatia nutritionala",
      image: method3,
      description: [
        "Sanatatea depinde de mai multi factori, inclusiv dieta zilnica. O alimentatie echilibrata si variata poate preveni afectiuni precum obezitatea, diabetul, bolile de inima si cancerul. Consumul adecvat de vitamine si minerale intareste imunitatea, mentine sanatatea pielii si a organelor. ",
        "Nutritia este esentiala pentru dezvoltarea fizica si cognitiva, mai ales in copilarie si adolescenta. Consultatia nutritionala creeaza un plan alimentar personalizat, adaptat la sanatatea, greutatea si preferintele fiecarei persoane, oferind ghidare si sustinere pe durata programului.",
      ],
    },
    {
      title: "Terapia florala Bach",
      image: method4,
      description: [
        "Terapia florala Bach foloseste remedii din extracte de flori salbatice pentru tratarea problemelor emotionale si a durerii. Acestea restabilesc usor echilibrul dintre minte si corp, eliminand emotiile negative, cum ar fi frica, ingrijorarea, ura, indecizia,  care interfereaza cu echilibrul fiintei in ansamblu. ",
        "Remediile florale n-au efecte adverse si pot fi administrate si copiilor. Pentru  echilibrarea starii psiho-emotionale se prepara un amestec personalizat din mai multe remedii in dependenta de indicatii.",
      ],
    },
    {
      title: "Aromaterapia",
      image: method5,
      description: [
        "Aromaterapia este o terapie holistica ce contribuie la echilibrarea sanatatii fizice, mentale si spirituale folosind uleiuri esentiale. Acestea sunt substante concentrate, extrase din diverse parti ale plantelor (radacini, frunze, flori, scoarta) prin distilare cu aburi sau presare, in special pentru citrice. ",
        "Aromaterapia ajuta la ameliorarea unor afectiuni fizice precum durerile de cap, musculare, indigestia, reumatismul si problemele respiratorii (sinuzita, bronsita, astm). Totodata, are beneficii psihologice, reducand stresul, fricile, depresia, insomnia si lipsa de concentrare.",
      ],
    },
  ];

  const reviews = [
    {
      name: "Elena, 55 ani",
      review:
        "Aveam dureri in umarul si cotul stang, dificultati de respiratie si bufeuri suparatoare. Dupa prima sedinta, am simtit ameliorare, iar dupa 5 sedinte, toate simptomele au disparut.",
    },
    {
      name: "Eugen, 45 ani",
      review:
        "Acum un an ma dureau genunchii cand ma ridicam pe scari si coboram. Nici nu puteam sa merg mult. Trei sedinte au fost suficient ca sa uit de durerea de genunchi. Recomand tuturor!",
    },
    {
      name: "Vasile, 48 ani",
      review:
        "Aveam dureri de spate din cauza muncii in constructii. Operatia de hernie de disc nu a rezolvat problema; nu puteam sa ma aplec sa ma incalt. Dupa o sedinta la dna Elena, durerile au disparut si am putut sa ma incalt fara dificultate.",
    },
    {
      name: "Mihaela, 27 ani",
      review:
        "M-am adresat cu dureri dese si mari de cap, mai greu ma concentram si foarte repede ma oboseam. In copilarie am avut mai multe traume la cap.  Sunt multumita de tratamentul profesionist acordat. Recomand cu incredere!",
    },
  ];

  const services = [
    "Terapia Bowen",
    "Kinesiologia aplicata",
    "Terapia florala Bach",
    "Aromaterapia",
    "Consultatia nutritionala",
  ];

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAppointmentSuccess, setIsAppointmentSuccess] = useState(false);
  const [isAppointmentSending, setIsAppointmentSending] = useState(false);
  function MakeAppointment(e) {
    e.preventDefault();
    const name = e.target.name.value;
    const phone = e.target.phone.value;

    if (name.length == 0 || phone.length == 0) {
      alert("Va rugam sa completati toate campurile.");
      return;
    }

    const data = {
      name,
      phone,
    };
    setIsAppointmentSending(true);
    axios
      .post(`${api_url}/appointment/`, data)
      .then((res) => {
        setIsAppointmentSuccess(true);
        setIsAppointmentSending(false);
      })
      .catch((err) => {
        alert("A aparut o eroare. Va rugam sa incercati din nou.");
        setIsAppointmentSending(false);
      });
  }

  const [selectedService, setSelectedService] = useState(services[0]);

  return (
    <div className="bg-background">
      <nav className="flex lg:grid sm:grid-cols-1 sm:px-6 relative lg:grid-cols-[2fr,1fr] md:grid-cols-2 md:grid md:px-10 justify-between gap-y-8 px-32 xl:px-28 lg:px-20 py-10 items-center">
        <div className="flex items-center sm:mx-auto lg:col-span-2 lg:mx-auto gap-x-2">
          <img src={logo} alt="logo" className="h-20 w-20" />
          <div className="font-extrabold text-5xl leading-9">
            STOP
            <br />
            DURERE
          </div>
        </div>
        <motion.button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          whileHover={{ scale: 1.025 }}
          whileTap={{ scale: 0.975 }}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute left-10 top-10 hidden sm:block"
        >
          <GiHamburgerMenu className="text-tertiary text-4xl" />
        </motion.button>
        <div className="flex font-bold md:order-3 sm:hidden md:col-span-2 md:justify-center text-2xl gap-x-10 justify-between">
          <a href="#methods">Metodele noastre</a>
          <a href="#faqs">Interbari frecvente</a>
          <a href="#reviews">Marturii de la clienti</a>
        </div>
        <div className="md:order-2 sm:hidden">
          <a href="#appointment">
            <motion.button
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              className="bg-primary text-white text-2xl rounded-xl font-black px-10 py-3 block ml-auto"
            >
              PROGRAMEAZA-TE
            </motion.button>
          </a>
        </div>
      </nav>
      <motion.div
        initial={{ x: "-100%" }}
        animate={{ x: isMenuOpen ? 0 : "-100%" }}
        transition={{ duration: 0.3 }}
        className="hidden pb-20 fixed sm:flex flex-col items-center justify-center gap-y-6 bg-background left-0 bottom-0 w-full h-screen z-50"
      >
        <button
          className="absolute top-5 right-5"
          onClick={() => setIsMenuOpen(false)}
        >
          <IoMdClose className="text-4xl" />
        </button>
        <div className="flex items-center gap-x-2 ">
          <img src={logo} alt="logo" className="h-20 w-20" />
          <div className="font-extrabold text-5xl leading-9">
            STOP
            <br />
            DURERE
          </div>
        </div>
        <div className="flex font-bold flex-col gap-y-8 mt-20 text-center text-2xl gap-x-10 justify-between">
          <a onClick={() => setIsMenuOpen(false)} href="#methods">
            Metodele noastre
          </a>
          <a onClick={() => setIsMenuOpen(false)} href="#faqs">
            Interbari frecvente
          </a>
          <a onClick={() => setIsMenuOpen(false)} href="#reviews">
            Marturii de la clienti
          </a>
        </div>
        <a onClick={() => setIsMenuOpen(false)} href="#appointment">
          <motion.button
            whileHover={{ scale: 1.025 }}
            whileTap={{ scale: 0.975 }}
            className="bg-primary text-white text-2xl rounded-xl font-black px-10 py-3"
          >
            PROGRAMEAZA-TE
          </motion.button>
        </a>
      </motion.div>
      <div className="flex mt-24 sm:mt-12 md:flex-col sm:flex-col">
        <div className="pl-32 xl:pl-28 lg:pl-20 md:w-full md:px-10 w-[55%] sm:w-full sm:px-6">
          <h1 className="text-tertiary sm:text-5xl text-7.5xl xl:text-7xl lg:text-6xl md:text-6xl md:leading-[4rem] lg:leading-[4rem] leading-[5.25rem] font-extrabold">
            AICI SI ACUM POTI SCAPA DIN DURERE PRIN{" "}
            <span className="text-secondary">
              <Typewriter
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
                cursor={true}
                loop={true}
                words={[
                  "P-DTR",
                  "TERAPIA BOWEN",
                  "KINESIOLOGIA APLICATA",
                  "CONSULTATIA NUTRITIONALA",
                  "TERAPIA FLORALA BACH",
                  "AROMATERAPIA",
                ]}
              />
            </span>
          </h1>
          <p className="font-medium sm:text-2xl text-3xl mt-2 lg:text-2xl md:text-2xl">
            Metodele noastre prezinta unele dintre cele mai profunde concepte
            terapeutice existente in lume in prezent.
            <span className="sm:hidden">
              {" "}
              Pot fi aplicate pentru orice dezechilibru in organism.
            </span>
          </p>
          <a href="#appointment">
            <motion.button
              whileHover={{ scale: 1.025 }}
              whileTap={{ scale: 0.975 }}
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="bg-primary text-white text-2xl sm:w-full rounded-xl font-black px-10 py-3 block mt-6"
            >
              PROGRAMEAZA O CONSULTATIE
            </motion.button>
          </a>
          <p className="text-2xl mt-4 font-medium">
            sau suna la{" "}
            <span className="text-secondary font-black">
              <Typewriter words={["068504628"]} />
            </span>
          </p>
        </div>
        <div className="w-[45%] md:w-full relative sm:w-full">
          <div className="w-[80rem] sm:h-[30rem] sm:w-[60rem] h-[45rem] -translate-x-10 rounded-t-full bg-primary/20"></div>
          <img
            src={person}
            className="absolute translate-y-3 bottom-0 right-0 w-[42rem]"
          />
        </div>
      </div>
      <hr className="h-1 w-full bg-primary" />
      <section className="px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <h2 className="mt-16">Ce se poate de tratat prin intermediul P-dtr?</h2>
        <p className="w-[60%] lg:w-4/5 subtitle md:w-full sm:w-full sm:mt-2">
          P-DTR este o metoda foarte blanda, neinvaziva si nedureroasa, bazata
          pe efectul manual in timp real al anumitor receptori senzoriali asupra
          corpului uman.
        </p>
        <div className="grid grid-cols-2 md:grid-cols-1 sm:grid-cols-1 md:gap-y-8 sm:gap-y-8 gap-x-20 xl:gap-x-12 lg:gap-x-8 xl:gap-y-8 lg:gap-y-6 gap-y-12 mt-6">
          {pdtrs.map((pdtr, index) => (
            <motion.div
              initial={{ x: index % 2 == 0 ? -100 : 100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{
                duration: 0.3,
                delay: index * 0.1,
                type: "tween",
                ease: "easeInOut",
              }}
              viewport={{ once: true }}
              className="bg-primary rounded-2xl relative"
            >
              <img
                src={pdtr.image}
                alt="pdtr"
                className="w-full h-72 object-cover rounded-t-2xl object-bottom"
              />
              <p className="text-white text-2xl font-medium p-4 md:text-xl lg:text-xl sm:text-xl">
                {pdtr.description}
              </p>
            </motion.div>
          ))}
        </div>
      </section>

      <section className="px-32 mt-24 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <a id="methods"></a>
        <h2>Afla mai multe despre metodele noastre</h2>
        <p className="w-[60%] subtitle lg:w-4/5 sm:w-full md:w-full sm:mt-2">
          Toate aceste terapii sunt terapii complementare medicinei alopate si
          ele nu pot substitui prescriptiile medicale.
        </p>
        <div className="flex flex-col gap-y-12 mt-6">
          {methods.map((method, index) => (
            <div
              className={
                " flex lg:flex-col sm:flex-col-reverse gap-y-6 md:flex-col rounded-2xl relative gap-x-4 " +
                (index % 2 == 0 ? "flex-row-reverse	" : "")
              }
            >
              <div className="flex text-left flex-col gap-y-6">
                {method.description.map((description) => (
                  <p className=" text-2xl font-medium">{description}</p>
                ))}

                <a href="#appointment">
                  <motion.button
                    whileHover={{ scale: 1.025 }}
                    whileTap={{ scale: 0.975 }}
                    onClick={() => setSelectedService(method.title)}
                    className="bg-primary text-white uppercase text-2xl rounded-xl font-black px-10 py-3 block"
                  >
                    SUNT INTERESAT DE {method.title}
                  </motion.button>
                </a>
              </div>
              <img
                src={method.image}
                alt="method"
                className="w-1/3 h-80 object-cover rounded-xl object-bottom md:w-full sm:w-full border-2 border-solid border-primary"
              />
            </div>
          ))}
        </div>
      </section>
      <section className="px-32 mt-20 xl:px-28 lg:px-20 md:px-10 sm:px-6 ">
        <a id="appointment"></a>
        <h2>Programeaza-te acum si scapa de probleme</h2>
        <p className="w-[60%] subtitle lg:w-4/5 md:w-full sm:w-full sm:mt-2">
          Odata completat formularul, vei fi contactat pentru a stabili un
          program convenabil pentru tine.
        </p>
        <div className="relative w-full">
          <img
            src={contact}
            alt="contact"
            className="w-full object-cover h-96 sm:h-[30rem] rounded-2xl mt-6"
          />
          <div className="absolute left-0 top-0 w-full h-full bg-primary/15"></div>
          <motion.div
            initial={{ width: "10%" }}
            whileInView={{ width: isAppointmentSuccess ? "100%" : "66.66%" }}
            viewport={{ once: true }}
            transition={{
              duration: 1.5,
              type: "tween",
              ease: "easeInOut",
            }}
            className="absolute left-0 top-0 from-40% h-full from-[#67C3AA]/80 to-transparent bg-gradient-to-r"
          ></motion.div>
          {isAppointmentSuccess ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.3,
              }}
              className="absolute-center text-center flex flex-col gap-y-4 w-full px-8"
            >
              <FaRegCircleCheck className="text-7xl mx-auto text-white" />
              <p className="text-white text-2xl font-medium">
                Multumim pentru programare! Veti fi contactat in cel mai scurt
                timp posibil.
              </p>
            </motion.div>
          ) : (
            <form
              onSubmit={MakeAppointment}
              className="grid grid-cols-2 sm:grid-cols-1 gap-x-6 gap-y-6 sm:px-4 sm:w-full absolute left-10 sm:left-0 top-14"
            >
              <motion.input
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.3,
                }}
                name="phone"
                placeholder="Numar de telefon"
                type="text"
                disabled={isAppointmentSending}
                className="bg-white py-4 px-4 rounded-lg outline-none w-96 sm:w-full font-medium text-2xl placeholder:italic placeholder:font-medium"
              />
              <motion.input
                disabled={isAppointmentSending}
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.5,
                }}
                name="name"
                placeholder="Nume complet"
                type="text"
                className="bg-white py-4 px-4 rounded-lg outline-none w-96 sm:w-full font-medium text-2xl placeholder:italic placeholder:font-medium"
              />
              <motion.select
                disabled={isAppointmentSending}
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.5,
                }}
                name="service"
                value={selectedService}
                onChange={(e) => setSelectedService(e.target.value)}
                className="bg-white py-4 px-4 rounded-lg italic text-black/50 outline-none w-96 sm:w-full font-medium text-2xl placeholder:italic placeholder:font-medium"
              >
                <option className="" disabled selected>
                  Serviciul Solicitat
                </option>
                {services.map((service) => (
                  <option value={service}>{service}</option>
                ))}
                <option value="P-dtr"> P-DTR</option>
              </motion.select>
              <motion.button
                disabled={isAppointmentSending}
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.4,
                  type: "tween",
                  ease: "easeInOut",
                  delay: 0.7,
                }}
                className="bg-primary text-white text-2xl w-96 sm:w-full rounded-xl font-black px-10 py-3 block"
              >
                PROGRAMEAZA-TE
              </motion.button>
            </form>
          )}
        </div>
      </section>
      <section className="px-32 xl:px-28 lg:px-20 mt-32 pt-10 pb-28 relative md:px-10 sm:px-6">
        <a id="reviews"></a>
        <div className="bg-primary py-40 z-0 absolute top-0 left-0 w-full"></div>
        <h2 className="text-white z-10 relative">
          Iata ce spun clientii nostri
        </h2>
        <div className="grid grid-cols-3 z-10 md:grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-y-6 bottom-0 gap-x-12 left-0 mt-6">
          {reviews.map((review, index) => (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.2,
                type: "tween",
                ease: "easeInOut",
                delay: index * 0.1,
              }}
              viewport={{ once: true }}
              className={
                "bg-white rounded-2xl p-6 drop-shadow-lg " +
                (index == reviews.length - 1 ? "hidden lg:block" : "")
              }
            >
              <p className="text-2xl font-medium italic">"{review.review}"</p>
              <p className="text-2xl font-bold mt-4">{review.name}</p>
            </motion.div>
          ))}
        </div>
      </section>

      {/* <section className="px-32 xl:px-28 lg:px-20 md:px-10 sm:px-6">
        <a id="faqs"></a>
        <h2>intrebari frecvente de la clientii nostri</h2>
        <div className="grid gap-x-12 mt-6 grid-cols-[3fr_1fr] md:grid-cols-1 sm:grid-cols-1 gap-y-6 lg:grid-cols-[2fr_1fr] lg:gap-x-6">
          <div className="flex flex-col gap-y-4">
            {questions.map((question, index) => (
              <motion.button
                key={index}
                initial={{ opacity: 0, y: -10 }}
                whileHover={{ scale: 1.015 }}
                whileTap={{ scale: 0.985 }}
                whileInView={{
                  opacity: 1,
                  y: 0,
                  transition: { delay: index * 0.1 },
                }}
                transition={{
                  duration: 0.2,
                  type: "tween",
                  ease: "easeInOut",
                }}
                viewport={{ once: true }}
                className="flex rounded-xl gap-x-4  sm:gap-x-2 items-center bg-white drop-shadow-md w-full p-4"
                onClick={() => {
                  setIsAnswerChanging(true);
                  setTimeout(() => {
                    setSelectedQuestion(question);
                  }, 200);
                }}
              >
                <div className="w-10 h-10 sm:w-8 min-w-8 min-h-8 lg:w-8 bg-primary rounded-full relative">
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity:
                        selectedQuestion.question === question.question ? 1 : 0,
                    }}
                    transition={{ duration: 0.2 }}
                  >
                    <FaCheck className="text-xl text-white absolute-center" />
                  </motion.span>
                </div>
                <p className="text-2xl text-left font-medium ml-4 sm:text-xl">
                  {question.question}
                </p>
              </motion.button>
            ))}
          </div>
          <div className="rounded-xl h-full font-medium sm:text-xl sm:p-4 text-2xl p-6 bg-white drop-shadow-md">
            <motion.span
              initial={{ opacity: 0, y: -10 }}
              animate={{
                opacity: isAnswerChanging ? 0 : 1,
                y: isAnswerChanging ? 10 : 0,
              }}
              transition={{ duration: 0.2 }}
            >
              {selectedQuestion.answer}
            </motion.span>
          </div>
        </div>
      </section> */}

      <footer className="bg-primary px-32 xl:px-28 lg:px-20 flex md:px-10 sm:px-6 md:flex-col sm:flex-col gap-y-8 justify-between text-white py-20 mt-24">
        <div className="w-1/3 flex flex-col gap-y-2 md:w-full sm:w-full">
          <div className="text-5xl font-extrabold ">STOP DURERE</div>
          <p className="text-lg font-medium leading-5">
            stopdurere.md este o platforma care se ocupa cu reabilitarea
            persoanelor ce sufera de anumite dureri, prin terapie, folosind
            modalitati moderne in procesul de tratare.
          </p>
          <div className="flex gap-x-4 text-4xl mt-4">
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="https://www.instagram.com/"
              target="_blank"
            >
              <FaInstagram className="" />
            </motion.a>
            <motion.a
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              href="https://www.facebook.com/"
              target="_blank"
            >
              <AiOutlineFacebook className="" />
            </motion.a>
          </div>
        </div>
        <div className="text-right w-1/3 flex flex-col gap-y-2 md:w-full sm:w-full sm:text-left md:text-left">
          <div className="text-5xl font-extrabold ">Contacte</div>
          <p className="text-lg font-medium leading-5">Telefon: 068504628</p>
          <p className="text-lg font-medium leading-5">
            Adresa de email: stopdureremd@gmail.com
          </p>
        </div>
      </footer>
      <div>
        <p className="text-center md:text-xl sm:text-xl text-2xl font-medium text-tertiary py-2">
          © {new Date().getFullYear()} StopDurere. Toate drepturile rezervate.
        </p>
      </div>
    </div>
  );
};

export default Home;
